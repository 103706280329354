import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';

String.prototype.toCamelCase = function () {
    return this.replace(/\b(\w)/g, function (match, capture) {
        return capture.toUpperCase();
    }).replace(/\s+/g, '');
}

const initializeApp = (config) => {
    /* eslint-disable */
    global.gConfig = config;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    root.render(<App modulo={'mensagens'} currentLanguage={'ptbr'} />);
}

Sentry.init({
    dsn: 'https://bfc288aa07ca48bcaa2986c14bdb1a5c@sentry.telessaude.ufsc.br/17',
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

fetch(`${process.env.PUBLIC_URL}/config/config.json`, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((config) => initializeApp(config));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
