import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    Formik,
    Field,
    FastField,
} from 'formik';
import axios from 'axios';
import {
    getHeaders
} from '../../request';
import PropTypes from 'prop-types';
import {
    SttGrid,
    SttInput,
    SttButton,
    SttAutocomplete,
    SttLoading,
    SttTranslateHook,
    SttDatePicker,
} from '@stt-componentes/core';
import * as yup from 'yup';
import {
    CATEGORIA,
    ASSUNTO,
    RESPONSAVEL,
    DATA_INICIAL,
    DATA_FINAL
} from './fieldNames';
import moment from 'moment';

const validationSchema = (strings) => {
    return yup.object().shape({
        [DATA_INICIAL]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [DATA_FINAL]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_INICIAL), strings.erroDataMenorDataInicial)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
    });
}

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(0.5)
    },
}));

const responsaveis = [
    {
        id: 'S',
        descricao: 'Sistema'
    },
    {
        id: 'U',
        descricao: 'Usuário'
    }
]

const umDia = 24 * 60 * 60 * 1000;
const estadoInicialForm = {
    categoria: null,
    assunto: '',
    responsavel: null,
    dataInicial: new Date((new Date()).getTime() - 30 * umDia),
    dataFinal: new Date(),
}

const Form = (props) => {
    const {
        buscar,
        callbackAndamento,
        callbackBusca,
        count,
        order,
        orderBy,
        page,
        resetPageOrder
    } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const [categoriasMensagem, setCategoriasMensagem] = useState([]);
    const [requestCount, setRequestCount] = useState(0);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    useEffect(() => {
        setRequestCount(1);
        axios.get(`${global.gConfig.url_base_mensagens}/categoria-mensagem`, { params: { ativa: true }, headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                data.data.push({
                    descricao: strings.semCategoria,
                    id: -1
                })
                setCategoriasMensagem(data.data);
            })
            .catch(err => console.log(err))
            .finally(() => setRequestCount(rq => rq - 1));
    }, []);

    return (
        <Formik
            innerRef={formRef}
            initialValues={estadoInicialForm}
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={(data) => {
                callbackAndamento(true);
                let params = {};
                if (data.assunto.trim()) {
                    params.assunto = data.assunto.trim()
                }

                if (data.categoria) {
                    params.categoria = data.categoria.id
                }

                if (data.responsavel) {
                    params.responsavel = data.responsavel.id;
                }

                if (data.dataInicial) {
                    params.dataInicial = moment(data.dataInicial).format('YYYY-MM-DD 00:00:00');
                }

                if (data.dataFinal) {
                    params.dataFinal = moment(data.dataFinal).format('YYYY-MM-DD 23:59:59');
                }

                if (orderBy && order) {
                    params.sort = orderBy;
                    params.direction = order;
                }

                const offset = (page * count);
                params.start = offset;
                params.count = count;

                const abortController = new AbortController();
                axios.get(`${global.gConfig.url_base_mensagens}/mensagens-enviadas`, { params, headers: getHeaders(), signal: abortController.signal })
                    .then((response) => {
                        const { data } = response.data;
                        callbackBusca(data);
                    })
                    .catch(err => {
                        callbackBusca({
                            totalRegistros: 0,
                            itens: []
                        });
                    })
                    .finally(() => {
                        callbackAndamento(false);
                        setRequestCount(reqAtual => reqAtual - 1);
                    });

                return () => abortController.abort()
            }}
        >
            {
                ({
                    isSubmitting,
                    values,
                    handleSubmit,
                    resetForm,
                    setFieldValue,
                    submitForm
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={2}>
                                <SttGrid item xs={12} sm={6} md={4} lg={2}>
                                    <FastField name={DATA_INICIAL}>
                                        {({
                                            field: { name, value, },
                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                            meta
                                        }) => {
                                            return (
                                                <SttDatePicker
                                                    label={strings.dataInicial}
                                                    inputprops={{
                                                        name: name
                                                    }}
                                                    maxDate={new Date()}
                                                    maxDateMessage={strings.erroDataFutura}
                                                    error={meta.touched && meta.error ? true : false}
                                                    onError={error => {
                                                        setFieldError(DATA_INICIAL, meta.error || error);
                                                    }}
                                                    value={value}
                                                    onBlur={() => {
                                                        setFieldTouched(DATA_INICIAL, true);
                                                    }}
                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    onChange={date => setFieldValue(DATA_INICIAL, date, true)}
                                                    onClose={() => setFieldTouched(DATA_INICIAL, true)}
                                                />
                                            );
                                        }}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={4} lg={2}>
                                    <FastField name={DATA_FINAL}>
                                        {({
                                            field: { name, value, },
                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                            meta
                                        }) => {
                                            return (
                                                <SttDatePicker
                                                    label={strings.dataFinal}
                                                    inputprops={{
                                                        name: name
                                                    }}
                                                    maxDate={new Date()}
                                                    maxDateMessage={strings.erroDataFutura}
                                                    error={meta.touched && meta.error ? true : false}
                                                    onError={error => {
                                                        setFieldError(DATA_FINAL, meta.error || error);
                                                    }}
                                                    value={value}
                                                    onBlur={() => {
                                                        setFieldTouched(DATA_FINAL, true);
                                                    }}
                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    onChange={date => setFieldValue(DATA_FINAL, date, true)}
                                                    onClose={() => setFieldTouched(DATA_FINAL, true)}
                                                />
                                            );
                                        }}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={6} lg>
                                    <Field name={ASSUNTO}>
                                        {({
                                            field,
                                        }) => (
                                            <SttInput
                                                {...field}
                                                label={strings.assunto}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                    <Field name={CATEGORIA}>
                                        {({
                                            field: { name, value, onBlur },
                                            meta,
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.categoria
                                                }}
                                                disabled={values.alteracao}
                                                getOptionLabel={option => (option?.descricao || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={categoriasMensagem}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(CATEGORIA, item || null);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                    <Field name={RESPONSAVEL}>
                                        {({
                                            field: { name, value, onBlur },
                                            meta,
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.responsavel
                                                }}
                                                disabled={values.alteracao}
                                                getOptionLabel={option => (option?.descricao || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={responsaveis}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(RESPONSAVEL, item || null);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => resetPageOrder()}
                                    >
                                        {strings.pesquisar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({
                                                values: estadoInicialForm
                                            });
                                            submitForm()
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                            <SttLoading
                                open={requestCount > 0}
                                text={strings.carregando}
                            />
                        </form>
                    )
                }
            }
        </Formik>
    );
};

Form.propTypes = {
    callbackBusca: PropTypes.func.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    buscar: PropTypes.bool.isRequired,
    resetPageOrder: PropTypes.func.isRequired,
};

export default Form;