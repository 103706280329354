export const CONSTANTES = {
    TYPE: {
        EMAIL: 'EMAIL'
    },
    ATIVIDADE: {
        TELEMEDICINA: 'TM',
        TELESSAUDE: 'TS',
        AMBOS: 'A'
    }
};

export const PERMISSOES = {
    MENSAGENS: 'MENSAGENS_MENSAGENS',
};