import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getHeaders } from '../../request';
import { Field, FieldArray, useFormikContext } from 'formik';
import { ENVIO } from './fieldNames';
import { SttCkEditor } from '@stt-componentes/ckeditor';
import { KEY_RETURN } from 'keycode-js';
import HttpStatus from 'http-status-codes';
import {
    SttGrid,
    SttExpansionPanel,
    SttAutocomplete,
    SttInput,
    SttFormHelperText,
    SttButton,
    SttAlerta,
    SttFileSelect,
    SttTranslateHook,
    SttHeading
} from '@stt-componentes/core';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    formWraper: {
        display: 'flex',
        alignItems: 'baseline'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },

    botaoMenos: {
        minWidth: 0
    },

    divExternaImagens: {
        maxHeight: '250px',
        overflow: 'auto'
    }
}));

const Email = (props) => {
    const { recipients, user } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const { values, setFieldValue, errors } = useFormikContext();
    const [emailsExternos, setEmailsExternos] = useState([]);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState(strings.erroDesconhecido);
    const [categoriasMensagem, setCategoriasMensagem] = useState([]);
    const [perfisRBAC, setPerfisRBAC] = useState([]);

    const MENSAGENS_API_BASE_URL = global.gConfig.url_base_mensagens;

    useEffect(() => {
        if (user) {
            // Carrega lista de emails externos
            axios
                .get(`${MENSAGENS_API_BASE_URL}/email-externo`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    setEmailsExternos(data.data);
                })
                .catch(err => console.log(err));

            // Carrega lista de categorias de mensagen
            axios
                .get(`${MENSAGENS_API_BASE_URL}/categoria-mensagem`, { params: { ativa: true }, headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    setCategoriasMensagem(data.data);
                })
                .catch(err => console.log(err));

            // Carrega lista de perfis RBAC
            axios
                .get(`${MENSAGENS_API_BASE_URL}/perfil`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    setPerfisRBAC(data.data);
                })
                .catch(err => console.log(err));
        }
    }, [user]);

    useEffect(() => {
        const selecionarTodos = values[ENVIO.RECIPIENTS].some(r => r.id === -1);
        if (selecionarTodos) {
            const todos = recipients.filter(r => r.id !== -1);
            setFieldValue(`${ENVIO.RECIPIENTS}`, todos);
        }
    }, [values[ENVIO.RECIPIENTS]]);

    useEffect(() => {
        if (values[ENVIO.RECIPIENTS].length) {
            setFieldValue(`${ENVIO.RECIPIENTS}`, []);
        }
    }, [recipients]);

    const addExternalEmail = (email) => {
        axios
            .post(`${MENSAGENS_API_BASE_URL}/email-externo`, { email }, { headers: getHeaders() })
            .then((response) => {
                setEmailsExternos([...emailsExternos, email]);
                setFieldValue(`${ENVIO.ADD_EXTERNAL_EMAILS}`, '');
                setFieldValue(`${ENVIO.EXTERNAL_EMAILS}`, [...values[ENVIO.EXTERNAL_EMAILS], email]);
            })
            .catch(err => {
                const { response } = err;
                setMensagemErro(strings.erroDesconhecido);
                if (response.status === HttpStatus.BAD_REQUEST) {
                    let msg = '';
                    const dadosResposta = response.data;
                    let arrMensagem = [];
                    dadosResposta.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                    setMensagemErro(msg);
                }
                setErro(true);
            });
    }

    return (
        <div>
            <SttExpansionPanel
                title={strings.envio}
                children={
                    <>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={ENVIO.ADD_EXTERNAL_EMAILS}>
                                    {({
                                        field,
                                        meta
                                    }) => (
                                        <div className={classes.formWraper}>
                                            <SttInput
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                {...field}
                                                label={strings.adicionarDestinatarioExterno}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : ''}
                                                onKeyUp={e => {
                                                    if (e.keyCode === KEY_RETURN && !meta.error) {
                                                        addExternalEmail(field.value);
                                                    }
                                                }}
                                            />
                                            <SttButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    addExternalEmail(field.value);
                                                }}
                                                disabled={(!field.value || meta.error) ? true : false}>
                                                {strings.adicionar}
                                            </SttButton>
                                        </div>
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={ENVIO.EXTERNAL_EMAILS}>
                                    {({ field: { name, value, onBlur }, meta }) => (
                                        <SttAutocomplete
                                            multiple
                                            limitTags={4}
                                            getLimitTagsText={escondidos => `+ ${escondidos}`}
                                            options={emailsExternos}
                                            getOptionLabel={(option) => option || ''}
                                            filterSelectedOptions
                                            inputprops={{
                                                name: name,
                                                label: strings.emailsExternos,
                                                error: meta.touched && meta.error ? meta.error : undefined
                                            }}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e, item) => {
                                                setFieldValue(`${ENVIO.EXTERNAL_EMAILS}`, item || []);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={ENVIO.RECIPIENTS}>
                                    {({ field: { name, value, onBlur }, meta }) => (
                                        <SttAutocomplete
                                            multiple
                                            limitTags={5}
                                            getLimitTagsText={escondidos => `+ ${escondidos}`}
                                            options={recipients}
                                            getOptionLabel={(option) => option && option.nome}
                                            filterSelectedOptions
                                            inputprops={{
                                                name: name,
                                                label: strings.destinatarios,
                                                error: meta.touched && meta.error ? meta.error : undefined
                                            }}
                                            noOptionsText={strings.useFiltros}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e, item) => {
                                                setFieldValue(`${ENVIO.RECIPIENTS}`, item || [])
                                            }}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={ENVIO.PROFILES}>
                                    {({ field: { name, value, onBlur }, meta }) => (
                                        <SttAutocomplete
                                            multiple
                                            limitTags={5}
                                            getLimitTagsText={escondidos => `+ ${escondidos}`}
                                            options={perfisRBAC}
                                            getOptionLabel={(option) => option && option.descricao}
                                            filterSelectedOptions
                                            inputprops={{
                                                name: name,
                                                label: strings.perfis,
                                                error: meta.touched && meta.error ? meta.error : undefined
                                            }}
                                            noOptionsText={strings.useFiltros}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e, item) => {
                                                setFieldValue(`${ENVIO.PROFILES}`, item || [])
                                            }}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={ENVIO.CATEGORY}>
                                    {({ field: { name, value, onBlur }, meta }) => (
                                        <SttAutocomplete
                                            required
                                            options={categoriasMensagem}
                                            getOptionLabel={(option) => option && option.descricao}
                                            filterSelectedOptions
                                            inputprops={{
                                                name: name,
                                                required: true,
                                                label: strings.categoria,
                                                error: meta.touched && meta.error ? meta.error : undefined
                                            }}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e, item) => {
                                                setFieldValue(`${ENVIO.CATEGORY}`, item || [])
                                            }}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>

                        {
                            values[ENVIO.CATEGORY]?.exige_complemento &&
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <Field name={ENVIO.OTHER_CATEGORY}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                {...field}
                                                label={strings.outraCategoria}
                                                required
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : ''}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                            </SttGrid>
                        }

                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={ENVIO.TITLE}>
                                    {({
                                        field,
                                        meta
                                    }) => (
                                        <SttInput
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                            {...field}
                                            label={strings.assunto}
                                            required
                                            error={meta.touched && meta.error ? true : false}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>

                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={ENVIO.CONTENT}>
                                    {({
                                        field: { value, name }, meta
                                    }) => (
                                        <>
                                            <SttCkEditor
                                                config={{
                                                    simpleUpload: {
                                                        uploadUrl: `${MENSAGENS_API_BASE_URL}/imagem`,
                                                        headers: {
                                                            Authorization: getHeaders().Authorization
                                                        }
                                                    }
                                                }}
                                                data={value}
                                                onChange={(event, editor) => {
                                                    let data = editor.getData();
                                                    setFieldValue(name, data);
                                                }}
                                                allowImage
                                            />
                                            <SttFormHelperText error={true}>{meta.touched && meta.error ? meta.error : undefined}</SttFormHelperText>
                                        </>
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttHeading variant="h4" color="primary">{strings.anexos}</SttHeading>
                                <FieldArray
                                    name={ENVIO.ATTACHMENTS}
                                    render={({ remove, insert, push }) => (
                                        <div>
                                            <div className={classes.divExternaImagens}>
                                                {
                                                    values[ENVIO.ATTACHMENTS].map((attachment, index) => (
                                                        <SttFileSelect
                                                            key={index}
                                                            labelInput={strings.anexo}
                                                            onFileChange={event => {
                                                                remove(index);
                                                                if (event) {
                                                                    insert(index, event.target.files[0]);
                                                                }
                                                            }}
                                                            file={attachment}
                                                            inputprops={{
                                                                name: ENVIO.ATTACHMENTS,
                                                                label: strings.anexo,
                                                                value: attachment && attachment.name || ''
                                                            }}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            <div>
                                                <SttButton
                                                    className={classes.botaoMais}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        push({});
                                                    }}
                                                >
                                                    +
                                                </SttButton>
                                                <SttButton
                                                    className={classes.botaoMenos}
                                                    variant="contained"
                                                    disabled={!values[ENVIO.ATTACHMENTS].length}
                                                    onClick={() => {
                                                        let index = values[ENVIO.ATTACHMENTS].length - 1;
                                                        remove(index);
                                                    }}
                                                    color="primary">
                                                    -
                                                </SttButton>
                                            </div>
                                        </div>
                                    )}
                                />

                            </SttGrid>
                        </SttGrid>
                        <SttAlerta
                            open={erro}
                            title={strings.erro}
                            message={mensagemErro}
                            type={'error'}
                            options={[{
                                title: strings.ok,
                                onClick: () => setErro(false)
                            }]}
                            onClose={() => setErro(false)}
                        />
                    </>
                }
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

// export default Email;
export default connect(mapStateToProps)(Email);





