import TYPES from '../types'

export const setAuthenticated = (authenticated) => {
    return {
        type: TYPES.SET_AUTHENTICATED,
        payload: { authenticated }
    };
}

export const setUser = (user) => {
    return {
        type: TYPES.SET_USER,
        payload: { user }
    };
}