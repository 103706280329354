import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttButton,
    SttCard,
    SttCardContent,
    SttGrid,
    SttInput,
    SttLoading,
    SttModal,
    SttTable,
    SttTableBody,
    SttTableCell,
    SttTableHead,
    SttTableRow,
    SttText,
    SttTranslateHook
} from '@stt-componentes/core';
import StickyBox from "react-sticky-box";
import axios from 'axios';
import { getHeaders } from '../../request';
import { Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    heading: {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(1)
    },
    divConteudo: {
        overflow: 'auto',
        lineBreak: 'anywhere',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(1)
    },
    card: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: '100%'
    },
    expand: {
        padding: 0,
        transform: 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    input: {
        marginBottom: theme.spacing(1.5)
    }
}));

function DetalhesMensagem({ setFecharModalDetalhes, idMensagem }) {
    const classes = useStyles();

    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [mensagem, setMensagem] = useState(null);
    const [filtroDestinatarios, setFiltroDestinatarios] = useState('');

    const [expandedExternal, setExpandedExternal] = useState(true);
    const [expanded, setExpanded] = useState(true);

    const handleExpandExternalClick = () => {
        setExpandedExternal(e => !e);
    };

    const handleExpandClick = () => {
        setExpanded(e => !e);
    };

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_mensagens}/mensagem/${idMensagem}`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                console.log(data.data)
                setMensagem(data.data);
            })
            .catch(err => console.log(err))

    }, []);

    const filtrarDestinatarios = (lista) => {
        if (filtroDestinatarios && filtroDestinatarios.length > 0) {
            return lista.filter(item => item.email.toLowerCase().includes(filtroDestinatarios.toLowerCase())
            );
        }

        return lista;
    }

    return (
        !mensagem
            ?
            <SttLoading open={true} />
            :
            <SttModal
                title={mensagem?.titulo || strings.mensagemEnviada}
                open={true}
                maxWidth="lg"
                fullWidth={true}
                outModalClose={setFecharModalDetalhes}
                iconClose={setFecharModalDetalhes}
                children={
                    <SttGrid container spacing={2}>
                        <SttGrid item xs={12} sm={12} md={12} lg={6}>
                            <SttCard variant="outlined" className={classes.card}>
                                <SttCardContent>
                                    <SttInput
                                        className={classes.input}
                                        label={strings.filtroDestinatarios}
                                        onChange={(event) => setFiltroDestinatarios(event.target.value)}
                                    />
                                    <SttTable stickyHeader>
                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell align="center" colSpan={1}>
                                                    {strings.emailsExternos}
                                                    <IconButton
                                                        className={clsx(classes.expand, {
                                                            [classes.expandOpen]: expandedExternal,
                                                        })}
                                                        onClick={handleExpandExternalClick}
                                                        onTouchStart={handleExpandExternalClick}
                                                        aria-expanded={expandedExternal}
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                </SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>

                                        <SttTableBody>
                                            <SttTableRow key={-10} >
                                                <SttTableCell style={{ padding: 0 }}>
                                                    <Collapse in={expandedExternal} timeout="auto" unmountOnExit orientation='vertical'>
                                                        {
                                                            filtrarDestinatarios(mensagem.externalRecipients).length === 0
                                                                ?
                                                                <SttTable >
                                                                    <SttTableBody>
                                                                        <SttTableRow key={-1}>
                                                                            <SttTableCell colSpan={1} align="center">
                                                                                {strings.nenhumRegistroEncontrado}
                                                                            </SttTableCell>
                                                                        </SttTableRow>
                                                                    </SttTableBody>
                                                                </SttTable>
                                                                :
                                                                <SttTable>
                                                                    <SttTableBody>
                                                                        {
                                                                            filtrarDestinatarios(mensagem.externalRecipients).map((row, index) => (
                                                                                <SttTableRow key={index}>
                                                                                    <SttTableCell colSpan={1}>
                                                                                        <SttText>
                                                                                            {row.email}
                                                                                        </SttText>
                                                                                    </SttTableCell>
                                                                                </SttTableRow>
                                                                            ))
                                                                        }
                                                                    </SttTableBody>
                                                                </SttTable>
                                                        }
                                                    </Collapse>
                                                </SttTableCell>
                                            </SttTableRow>
                                        </SttTableBody>

                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell align="center" colSpan={1}>
                                                    {strings.destinatarios}
                                                    <IconButton
                                                        className={clsx(classes.expand, {
                                                            [classes.expandOpen]: expanded,
                                                        })}
                                                        onClick={handleExpandClick}
                                                        onTouchStart={handleExpandClick}
                                                        aria-expanded={expanded}
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                </SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>
                                        <SttTableBody>
                                            <SttTableRow key={-10} >
                                                <SttTableCell style={{ padding: 0 }}>
                                                    <Collapse in={expanded} timeout="auto" unmountOnExit orientation='vertical'>
                                                        {
                                                            filtrarDestinatarios(mensagem.recipients).length === 0
                                                                ?
                                                                <SttTable >
                                                                    <SttTableBody>
                                                                        <SttTableRow key={-1}>
                                                                            <SttTableCell colSpan={1} align="center">
                                                                                {strings.nenhumRegistroEncontrado}
                                                                            </SttTableCell>
                                                                        </SttTableRow>
                                                                    </SttTableBody>
                                                                </SttTable>
                                                                :
                                                                <SttTable>
                                                                    <SttTableBody>
                                                                        {
                                                                            filtrarDestinatarios(mensagem.recipients).map((row, index) => (
                                                                                <SttTableRow key={index}>
                                                                                    <SttTableCell>
                                                                                        <SttText>
                                                                                            {row.nome}:&nbsp;{row.email}
                                                                                        </SttText>
                                                                                    </SttTableCell>
                                                                                </SttTableRow>
                                                                            ))
                                                                        }
                                                                    </SttTableBody>
                                                                </SttTable>
                                                        }
                                                    </Collapse>
                                                </SttTableCell>
                                            </SttTableRow>

                                        </SttTableBody>
                                    </SttTable>
                                </ SttCardContent>
                            </ SttCard>
                        </SttGrid>
                        <SttGrid item xs={12} sm={12} md={12} lg={6}>
                            <SttCard variant="outlined" className={classes.card}>
                                <SttCardContent>
                                    <StickyBox>
                                        <div className={classes.divConteudo} dangerouslySetInnerHTML={{ __html: mensagem.conteudo }} />
                                    </StickyBox>
                                </ SttCardContent>
                            </ SttCard>
                        </SttGrid>
                    </SttGrid>
                }
                footer={
                    < div >
                        <SttButton variant="contained" color="primary" onClick={setFecharModalDetalhes}>
                            {strings.fechar}
                        </SttButton>
                    </div >
                }
            />
    );
}

export default DetalhesMensagem;