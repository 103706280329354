import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getHeaders } from '../../request';
import { Field, useFormikContext } from 'formik';
import { FILTROS } from './fieldNames';
import { 
    SttGrid, 
    SttExpansionPanel,
    SttAutocomplete,
    SttHeading,
    SttNumberInput,
    SttDivider,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        width: '100%',
        height: '100%',
    }
}));

const Email = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const { values, setFieldValue, handleSubmit, resetForm, isSubmitting } = useFormikContext();

    const [cbos, setCbos] = useState([]);
    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);

    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    // Carrega lista de UF's, CBO família e emails externos
    useEffect(() => {
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/localizacao/estado`)
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setUfs(itens);
                }
            })
            .catch(err => console.log(err));

        axios
            .get(`${UTILITARIOS_API_BASE_URL}/ocupacao/cbo-familia`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                const { itens } = data.data;
                setCbos(itens);
            })
            .catch(err => console.log(err));
    }, []);
        
    // Carrega lista de Municípios
    useEffect(() => {
        if (values[FILTROS.UF]) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${values[FILTROS.UF].id}`)
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setMunicipios(itens);
                    } else {
                        setMunicipios([]);
                    }
                })
                .catch(err => console.log(err));
        } else {
            setMunicipios([]);
        }

    }, [values[FILTROS.UF]]);

    return (
        
        <div>
            <SttExpansionPanel
                title={strings.destinatarios}
                children={
                    <>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <Field name={FILTROS.CBO_FAMILIA}>
                                    {({
                                        field: { name, value, onBlur }
                                    }) => (
                                        <>
                                            <SttAutocomplete 
                                                inputprops={{
                                                    name: name,
                                                    label: strings.cboFamilia
                                                }}
                                                getOptionLabel={option => option && option.descritor || ''}
                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                options={cbos}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(`${FILTROS.CBO_FAMILIA}`, item || null);
                                                }}
                                            />
                                        </>
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                        <br />
                        <SttHeading variant="h4" color="primary">{strings.vinculo}</SttHeading>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12} sm={2}>
                                <Field name={FILTROS.UF}>
                                    {({
                                        field: { name, value, onBlur }
                                    }) => (
                                        <SttAutocomplete
                                            inputprops={{
                                                name: name,
                                                label: strings.uf
                                            }}
                                            getOptionLabel={option => option && option.sigla || ''}
                                            getOptionSelected={(option, val) => option && val && option.id === val.id}
                                            options={ufs}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e, item) => {
                                                setFieldValue(`${FILTROS.UF}`, item || null);
                                                setFieldValue(`${FILTROS.MUNICIPIO}`, null);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                            <SttGrid item xs={12} sm={8}>
                                <Field name={FILTROS.MUNICIPIO}>
                                    {({
                                        field: { name, value, onBlur }
                                    }) => (
                                        <SttAutocomplete 
                                            inputprops={{
                                                name: name,
                                                label: strings.municipio
                                            }}
                                            getOptionLabel={option => option && option.nome || ''}
                                            getOptionSelected={(option, val) => option && val && option.id === val.id}
                                            options={municipios}
                                            value={value}
                                            onBlur={onBlur}
                                            disabled={!values[FILTROS.UF]?.id}
                                            onChange={(e, item) => setFieldValue(`${FILTROS.MUNICIPIO}`, item || null)}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                            <SttGrid item xs={12} sm={2}>
                                <Field name={FILTROS.CNES}>
                                    {({ field }) => (
                                        <SttNumberInput 
                                            inputProps={{
                                                maxLength: 7,
                                            }}
                                            label={strings.cnes}
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <div className={classes.buttonWrapper}>
                                    <SttButton variant="contained" color="primary" nomarginleft="true" onClick={handleSubmit} disabled={isSubmitting}>
                                        {strings.buscar}
                                    </SttButton>
                                    <SttButton variant="outlined" color="primary" onClick={resetForm}>
                                        {strings.limpar}
                                    </SttButton>
                                </div>
                            </SttGrid>
                        </SttGrid>
                    </>
                }
            />
            <SttDivider />
        </div>
    )
}

export default Email;