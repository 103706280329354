import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttButton,
    SttCard,
    SttCardContent,
    SttHeading,
    SttModal,
    SttTranslateHook
} from '@stt-componentes/core';
const useStyles = makeStyles(theme => ({
    heading: {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(1)
    },
    divConteudo: {
        overflow: 'auto',
        // lineBreak: 'anywhere',
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)
    },
    card: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

function PreVisualizacaoNotificacao({ setPreVisualizar, notificacao }) {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);


    function handleCloseModal() {
        setPreVisualizar(null);
    }

    function handleClick(event) {
        if (event.target.tagName.toLowerCase() === 'a') {
            window.open(event.target.href, '_blank');
            event.preventDefault();
        }
    };

    return (
        <>
            <SttModal
                title={strings.preVisualizacao}
                open={true}
                maxWidth="lg"
                fullWidth={true}
                outModalClose={handleCloseModal}
                iconClose={handleCloseModal}
                children={
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardContent>
                            <SttHeading variant="h3" className={classes.heading} color="primary">{notificacao.assunto}</SttHeading>
                            <div onClick={handleClick} className={classes.divConteudo}>
                                <div dangerouslySetInnerHTML={{ __html: notificacao.conteudo }} />
                            </div>
                        </SttCardContent>
                    </SttCard>
                }
                footer={
                    <SttButton variant="contained" color="primary" onClick={handleCloseModal}>
                        {strings.fechar}
                    </SttButton>
                }
            />
        </>

    );
}

export default PreVisualizacaoNotificacao;