export const removeIdKeys = (obj) => {
    if (obj instanceof Array) {
        // Se for um array, aplicar a remoção em cada elemento
        return obj.map(item => removeIdKeys(item));
    } else if (obj instanceof Object) {
        // Se for um objeto, remover a chave "_id"
        const newObj = {};
        Object.keys(obj).forEach(key => {
            if (key !== "_id") {
                newObj[key] = removeIdKeys(obj[key]);
            }
        });
        return newObj;
    } else {
        // Se for qualquer outro tipo de valor, manter inalterado
        return obj;
    }
}
