import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    Formik,
    Field,
} from 'formik';
import axios from 'axios';
import {
    getHeaders
} from '../../request';
import PropTypes from 'prop-types';
import {
    SttGrid,
    SttInput,
    SttButton,
    SttAutocomplete,
    SttLoading,
    SttTranslateHook,
    SttFormControl,
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
} from '@stt-componentes/core';
import * as yup from 'yup';
import {
    CATEGORIA,
    ASSUNTO,
    FAVORITA
} from './fieldNames';

const validationSchema = () => {
    return yup.object().shape({
    });
}

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(0.5)
    },
}));

const estadoInicialForm = {
    [CATEGORIA]: null,
    [ASSUNTO]: '',
    [FAVORITA]: false
}

const Form = (props) => {
    const {
        buscar,
        callbackAndamento,
        callbackBusca,
        count,
        order,
        orderBy,
        page,
        resetPageOrder,
        setCadastrarNotificacao,
    } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema();
    const formRef = useRef();
    const classes = useStyles();

    const [categoriasMensagem, setCategoriasMensagem] = useState([]);
    const [requestCount, setRequestCount] = useState(0);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    useEffect(() => {
        setRequestCount(1);
        axios.get(`${global.gConfig.url_base_mensagens}/categoria-mensagem`, { params: { ativa: true }, headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setCategoriasMensagem(data.data);
            })
            .catch(err => console.log(err))
            .finally(() => setRequestCount(rq => rq - 1));
    }, []);

    return (
        <Formik
            innerRef={formRef}
            initialValues={estadoInicialForm}
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={(data) => {
                callbackAndamento(true);
                let params = {};
                if (data.assunto.trim()) {
                    params.assunto = data.assunto.trim()
                }

                if (data.categoria) {
                    params.categoria = data.categoria.id
                }

                if (data.favorita) {
                    params.favorita = data.favorita;
                }

                if (orderBy && order) {
                    params.sort = orderBy;
                    params.direction = order;
                }

                const offset = (page * count);
                params.start = offset;
                params.count = count;

                const abortController = new AbortController();
                axios.get(`${global.gConfig.url_base_mensagens}/notificacao`, { params, headers: getHeaders(), signal: abortController.signal })
                    .then((response) => {
                        const { data } = response.data;
                        callbackBusca(data);
                    })
                    .catch(err => {
                        callbackBusca({
                            totalRegistros: 0,
                            itens: []
                        });
                    })
                    .finally(() => {
                        callbackAndamento(false);
                        setRequestCount(reqAtual => reqAtual - 1);
                    });

                return () => abortController.abort()
            }}
        >
            {
                ({
                    isSubmitting,
                    values,
                    handleSubmit,
                    resetForm,
                    setFieldValue,
                    submitForm
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={4}>
                                    <Field name={ASSUNTO}>
                                        {({
                                            field,
                                        }) => (
                                            <SttInput
                                                {...field}
                                                label={strings.assunto}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3}>
                                    <Field name={CATEGORIA}>
                                        {({
                                            field: { name, value, onBlur },
                                            meta,
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.categoria
                                                }}
                                                disabled={values.alteracao}
                                                getOptionLabel={option => (option?.descricao || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={categoriasMensagem}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(CATEGORIA, item || null);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3}>
                                    <Field name={FAVORITA}>
                                        {({
                                            field: { value },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttFormControl variant="outlined">
                                                <SttFormGroup>
                                                    <SttFormControlLabel
                                                        control={
                                                            <SttCheckbox
                                                                value={value}
                                                                color="primary"
                                                                checked={values[FAVORITA]}
                                                                onChange={(event) => setFieldValue(FAVORITA, event.target.checked)}
                                                            />
                                                        }
                                                        label={strings.notificacoesFavoritas}
                                                    />
                                                </SttFormGroup>
                                            </SttFormControl>
                                        )}
                                    </Field>
                                </SttGrid>
                            </SttGrid>

                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => resetPageOrder()}
                                    >
                                        {strings.pesquisar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({
                                                values: estadoInicialForm
                                            });
                                            submitForm()
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setCadastrarNotificacao(true)}>
                                        {strings.cadastrar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                            <SttLoading
                                open={requestCount > 0}
                                text={strings.carregando}
                            />
                        </form>
                    )
                }
            }
        </Formik>
    );
};

Form.propTypes = {
    callbackBusca: PropTypes.func.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    buscar: PropTypes.bool.isRequired,
    resetPageOrder: PropTypes.func.isRequired,
};

export default Form;