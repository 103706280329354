import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Filtros from '../../componentes/email/filtros';
import Envio from '../../componentes/email/envio';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttContainer,
    SttAlerta,
    SttGrid,
    SttButton,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { CONSTANTES } from '../../common/Constants';
import { FILTROS, ENVIO } from '../../componentes/email/fieldNames';
import { filterValidationSchema, envioValidationSchema } from '../../componentes/email/validationSchema';
import HttpStatus from 'http-status-codes';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        width: '100%',
        height: '100%',
    }
}));

const Email = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schemaEnvio = envioValidationSchema(strings);
    const [filterInitialValues, setFilterInicialValues] = useState(null);
    const [envioInitialValues, setEnvioInitialValues] = useState(null);
    const [recipients, setRecipients] = useState([]);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
    const [tipoAlerta, setTipoAlerta] = useState('success');
    const [mensagemAlerta, setMensagemAlerta] = useState(strings.mensagemEnviada);
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const MENSAGENS_API_BASE_URL = global.gConfig.url_base_mensagens;

    useEffect(() => {
        const filterValues = {
            [FILTROS.ATIVIDADE]: CONSTANTES.ATIVIDADE.AMBOS,
            [FILTROS.CBO_FAMILIA]: null,
            [FILTROS.UF]: null,
            [FILTROS.MUNICIPIO]: null,
            [FILTROS.CNES]: ''
        };
        setFilterInicialValues(filterValues);

        const envioValues = {
            [ENVIO.TYPE]: CONSTANTES.TYPE.EMAIL,
            [ENVIO.ADD_EXTERNAL_EMAILS]: '',
            [ENVIO.EXTERNAL_EMAILS]: [],
            [ENVIO.RECIPIENTS]: [],
            [ENVIO.CATEGORY]: [],
            [ENVIO.OTHER_CATEGORY]: '',
            [ENVIO.TITLE]: '',
            [ENVIO.CONTENT]: '',
            [ENVIO.ATTACHMENTS]: [],
            [ENVIO.PROFILES]: []
        };

        setEnvioInitialValues(envioValues);
    }, []);

    return (
        <SttContainer>
            {
                filterInitialValues && <Formik
                    enableReinitialize
                    initialValues={filterInitialValues}
                    validationSchema={filterValidationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        let dados = { ...data };

                        if (!dados[FILTROS.CNES]) {
                            delete dados[FILTROS.CNES];
                        }

                        if (dados[FILTROS.CBO_FAMILIA]) {
                            dados[FILTROS.CBO_FAMILIA] = dados[FILTROS.CBO_FAMILIA].id;
                        } else {
                            delete dados[FILTROS.CBO_FAMILIA];
                        }

                        if (dados[FILTROS.UF]) {
                            dados[FILTROS.UF] = dados[FILTROS.UF].id;
                        } else {
                            delete dados[FILTROS.UF];
                        }

                        if (dados[FILTROS.MUNICIPIO]) {
                            dados[FILTROS.MUNICIPIO] = dados[FILTROS.MUNICIPIO].id;
                        } else {
                            delete dados[FILTROS.MUNICIPIO];
                        }

                        if (dados[FILTROS.ATIVIDADE] === CONSTANTES.ATIVIDADE.AMBOS) {
                            delete dados[FILTROS.ATIVIDADE];
                        }

                        axios
                            .get(`${MENSAGENS_API_BASE_URL}/destinatario`, { params: dados, headers: getHeaders() })
                            .then((response) => {
                                let destinatarios = response.data.data;
                                if (destinatarios.length) {
                                    destinatarios.unshift({ id: -1, nome: 'Selecionar todos' });
                                }
                                setRecipients(destinatarios);
                            })
                            .catch(err => {
                                console.log(err);
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({ isSubmitting }) => {
                            return (
                                <form noValidate>
                                    <Filtros />
                                    <SttLoading open={isSubmitting} />
                                </form>
                            )
                        }
                    }
                </Formik>

            }
            {
                envioInitialValues && <Formik
                    enableReinitialize
                    initialValues={envioInitialValues}
                    validationSchema={schemaEnvio}
                    onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                        let dados = { ...data };

                        const formData = new FormData();
                        formData.append('content', dados.content);
                        formData.append('externalEmails', dados.externalEmails.join(','));
                        formData.append('profiles', (data.profiles.map(p => p.identificador)).join(','));
                        formData.append('recipients', (data.recipients.map(r => r.id)).join(','));
                        formData.append('category', data.category.id);
                        if (data.category && data.category.exige_complemento) {
                            formData.append('needsComplement', true);
                            formData.append('otherCategory', data.other_category);
                        }
                        formData.append('title', dados.title);
                        formData.append('type', dados.type);

                        dados.attachments.forEach((file, index) => {
                            if (file?.name) {
                                formData.append(`attachments-${index}`, file);
                            }
                        });

                        axios.post(`${MENSAGENS_API_BASE_URL}/mensagem`, formData, { headers: { ...getHeaders(),  'Content-Type': 'multipart/form-data' } })
                            .then((response) => {
                                setTipoAlerta('success');
                                setTituloAlerta(strings.sucesso);
                                setMensagemAlerta(strings.mensagemEnviada);
                                setOpcoesAlerta([{
                                    title: strings.ok,
                                    onClick: handleCloseAlerta
                                }]);
                                resetForm();
                            })
                            .catch(err => {
                                console.log(err);
                                const { response } = err;
                                let msg = strings.erroDesconhecido;
                                setMensagemAlerta(msg);

                                if (response) {
                                    if (response.status === HttpStatus.BAD_REQUEST) {
                                        const dadosResposta = response.data;
                                        let arrMensagem = [];
                                        dadosResposta.errors.forEach(error => {
                                            arrMensagem.push(`- ${error.message}`);
                                        });
                                        msg = arrMensagem.join('\n');
                                        setTituloAlerta(dadosResposta.message);
                                        setMensagemAlerta(msg);
                                    } else {
                                        setTituloAlerta(strings.erro);
                                    }
                                } else {
                                    setTituloAlerta(strings.erro);
                                }
                                setTipoAlerta('error');
                                setOpcoesAlerta([{
                                    title: strings.ok,
                                    onClick: handleCloseAlerta
                                }]);
                            })
                            .finally(() => {
                                setSubmitting(false);
                                setMostrarAlerta(true);
                            });
                    }}
                >
                    {
                        ({ handleSubmit, resetForm, isSubmitting }) => {
                            return (
                                <form noValidate>
                                    <Envio recipients={recipients} />

                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <div className={classes.buttonWrapper}>
                                                <SttButton variant="contained" color="primary" nomarginleft="true" onClick={handleSubmit} disabled={isSubmitting}>
                                                    {strings.enviar}
                                                </SttButton>
                                                <SttButton variant="outlined" color="primary" onClick={resetForm}>
                                                    {strings.limpar}
                                                </SttButton>
                                            </div>
                                        </SttGrid>
                                    </SttGrid>
                                    <SttLoading open={isSubmitting} />
                                </form>
                            )
                        }
                    }
                </Formik>
            }
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
        </SttContainer>
    );
};
export default Email;