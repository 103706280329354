export const FILTROS = {
    FILTROS: 'filtros',
    CBO_FAMILIA: "cboFamilia",
    UF: "estado",
    MUNICIPIO: "municipio",
    CNES: 'cnes',
    MODULO: 'modulo',
    PERFIL: 'perfil',
    FUNCIONARIOS: 'funcionario'
};

export const ENVIO = {
    ENVIO: 'envio',
    CATEGORIA: 'categoria',
    COMPLEMENTO_CATEGORIA: 'complementoCategoria',
    ASSUNTO: 'assunto',
    CONTEUDO: 'conteudo',
    LEITURA_OBRIGATORIA: 'leituraObrigatoria',
    DATA_FINAL_EXIBICAO: 'dataFinalExibicao',
    DATA_INICIAL_EXIBICAO: 'dataInicialExibicao',
    ATIVA: 'ativa',
    FAVORITA: 'favorita',
    BLOQUEIO_TELA: 'bloqueioTela'
};

export const ATIVA = 'ativa';