import { combineReducers } from 'redux';

import TYPES from './types';

const INITIAL_STATE = {
    isAuthenticated: false,
    user: null
};

const index = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index,
});
