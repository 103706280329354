import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter, } from "react-router-dom";
import {
    MateriaisSuporte,
    SttHeader,
    SttHelpdeskShortcut,
    SttMainNavigationBar,
    SttNotificationScreen,
    SttSplashScreen,
    SttTranslateHook
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '../security/acl';
import { PERMISSOES } from '../common/Constants';
import { getHeaders } from '../request';

const Menu = ({ user }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const history = useHistory();
    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);

    useEffect(() => {
        let menuOptions = [];
        if (user) {
            if (!temPermissaoRBAC(user, PERMISSOES.MENSAGENS)) {
                history.push(`/nao-autorizado`);
                return;
            }

            menuOptions = [...menuOptions, {
                id: '/email',
                text: strings.email,
                button: true,
                onClick: () => {
                    history.push("/email");
                }
            }];

            menuOptions = [...menuOptions, {
                id: '/mensagens-enviadas',
                text: strings.mensagensEnviadas,
                button: true,
                onClick: () => {
                    history.push("/mensagens-enviadas");
                }
            }];

            menuOptions = [...menuOptions, {
                id: '/notificacoes',
                text: strings.notificacoes,
                button: true,
                onClick: () => {
                    history.push("/notificacoes");
                }
            }];

            setSubmenus(menuOptions);
        }
    }, [user]);

    useEffect(() => {
        submenus.forEach((menu, i) => {
            if (menu.id === history.location.pathname) {
                setTabAtual(i);
            }
        });
    }, [submenus, history]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS((o) => !o),
                    onClickMateriaisSuporte: () => setOpenMS((o) => !o),
                    strings,
                }}
            />
            <SttMainNavigationBar
                titulo={strings.moduloMensagens}
                config={global.gConfig}
                submenus={submenus}
                selectedTab={tabAtual}
                callbackNavigationBar={(a, b) => { console.log(a, b) }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                open={openSS}
                setOpen={setOpenSS}
            />
            {
                user && temPermissaoRBAC(user, PERMISSOES.MENSAGENS) &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={user.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={user}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS}
                setOpen={setOpenMS}
            />

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

export default connect(mapStateToProps, null)(withRouter(Menu));
