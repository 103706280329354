export const FILTROS = {
    CBO_FAMILIA: "cboFamilia",
    UF: "uf",
    MUNICIPIO: "municipio",
    CNES: 'cnes'
};

export const ENVIO = {
    RECIPIENTS: 'recipients',
    ADD_EXTERNAL_EMAILS: 'addExternalEmails',
    EXTERNAL_EMAILS: 'externalEmails',
    TYPE: 'type',
    TITLE: 'title',
    CATEGORY: 'category',
    OTHER_CATEGORY: 'other_category',
    CONTENT: 'content',
    ATTACHMENTS: 'attachments',
    PROFILES: 'profiles'
};