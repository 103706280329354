const temPerfilRBAC = (usuario, perfil) => {
    if (!usuario.perfisRBAC || !perfil || !usuario) {
        return false;
    }
    if (Array.isArray(perfil)) {
        return perfil.some(p => usuario.perfisRBAC.some(prbac => prbac.identificador === p));
    }
    return usuario.perfisRBAC.some(p => p.identificador === perfil);
};

const temPermissaoRBAC = (usuario, permissao) => {
    if (!usuario || !usuario.permissoesRBAC || !permissao) {
        return false;
    }
    if (Array.isArray(permissao)) {
        return permissao.some(p => usuario.permissoesRBAC.includes(p));
    }
    return usuario.permissoesRBAC.includes(permissao);
};

const temPermissaoRede = (rede, permissao) => {
    if (!permissao || !rede) {
        return false;
    }

    return rede.permissoes?.some(p => p === permissao);
};

export { temPerfilRBAC, temPermissaoRBAC, temPermissaoRede };