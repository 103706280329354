import * as yup from 'yup'
import { FILTROS, ENVIO } from './fieldNames';

export const filterValidationSchema = (strings) => {
    return yup.object().shape({
        [FILTROS.CNES]: yup.number(),
        [FILTROS.UF]: yup.object()
            .shape({
                id: yup.number(),
                codigo_ibge: yup.number(),
                nome: yup.string(),
                sigla: yup.string(),
                id_pais: yup.number()
            })
            .nullable(),
        [FILTROS.MUNICIPIO]: yup.object()
            .shape({
                id: yup.number(),
                nome: yup.string(),
                id_estado: yup.number(),
                latitude: yup.number(),
                longitude: yup.number(),
                codigo_ibge: yup.number(),
                nome_estado: yup.string()
            })
            .nullable(),
        [FILTROS.CBO_FAMILIA]: yup.object()
            .shape({
                codigo: yup.number(),
                id: yup.number(),
                descritor: yup.string()
            })
            .nullable(),
    });
};

export const envioValidationSchema = (strings) => {
    return yup.object().shape({
        [ENVIO.ADD_EXTERNAL_EMAILS]: yup.string().email(strings.emailInvalido).max(255).trim(),
        [ENVIO.CATEGORY]: yup.object().shape({
            descricao: yup.string(),
            exige_complemento: yup.boolean()
        }).nullable().required(strings.campoObrigatorio),
        [ENVIO.OTHER_CATEGORY]: yup.string().when(ENVIO.CATEGORY, {
            is: (val) => val?.exige_complemento,
            then: yup.string().max(255).trim().required(strings.campoObrigatorio)
        }),
        [ENVIO.TITLE]: yup.string().max(255).trim().required(strings.campoObrigatorio),
        [ENVIO.CONTENT]: yup.string()
            .trim()
            .test('html-valido', strings.erroConteudoHtml, (html) => {
                html = (html || '').replace(/<[^>]*>?/gm, '');
                if (html.length === 0 || html.length >= 10) {
                    return true;
                }
                return false;
            }).required(strings.campoObrigatorio)
    }, [[ENVIO.EXTERNAL_EMAILS, ENVIO.RECIPIENTS]]).test('destinatarios', strings.campoObrigatorioDestinatario, function (value) {
        const { externalEmails, profiles, recipients } = value;

        const arrays = [externalEmails, profiles, recipients];

        let erros = [];
        if (!externalEmails.length && !profiles.length && !recipients.length) {
            erros = [
                new yup.ValidationError(
                    strings.campoObrigatorioDestinatario,
                    null,
                    'externalEmails'
                ),
                new yup.ValidationError(
                    strings.campoObrigatorioDestinatario,
                    null,
                    'profiles'
                ),
                new yup.ValidationError(
                    strings.campoObrigatorioDestinatario,
                    null,
                    'recipients'
                )
            ]
        }

        if (erros.length === 0) { return true }

        return new yup.ValidationError(erros);
    });
};